<style lang="less" scoped>
  .group-select {
    display: flex;
    flex-direction: column;
  }
  .select-list {
    flex: 1;
    height: 0;
    overflow: auto;
    .item {
      padding: 5px 10px;
      cursor: pointer;
      transition: all .3s;
      &:hover {
        background-color: rgba(47, 91, 234, .1);
      }
      &:hover, &.active {
        color: #2F5BEA;
      }
    }
  }
  .search {
    margin: 10px;
  }
</style>

<template>
  <div class="group-select">
    <div class="search">
      <Input placeholder="名称搜索" v-model="searchKey" clearable @on-blur="onQueryChange" search @on-search="onQueryChange" @on-clear="onQueryChange" />
    </div>
    <div class="select-list">
      <template v-if="defaultItem">
        <div class="item" :class="{ active: groupId === defaultItem.id }" v-show="defaultItem && !query" :value="defaultItem.id">{{ defaultItem.name }}</div>
      </template>
      <div class="item" :class="{ active: groupId === item.id }" @click="onValueChange(item)" v-for="item in groupList" :key="item.id" :value="item.id">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
import request from '../../../../../api'
export default {
  props: {
    value: { type: Number, default: null }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data () {
    return {
      groupId: null,
      loading: false,
      groupList: [],
      defaultItem: null,
      searchKey: null,
      query: null
    }
  },
  watch: {
    value (val) {
      if (val !== this.groupId) {
        this.loadData()
      }
    }
  },
  methods: {
    onValueChange (v) {
      this.$emit('select', v)
      if (v.id !== this.value) {
        this.$emit('input', v.id)
      }
    },
    onQueryChange () {
      if (this.searchKey !== this.query) {
        const query = this.query = this.searchKey
        let groupList = []
        if (query) {
          groupList = this.$baseList.filter(v => {
            return (v.name || '').indexOf(query) > -1
          }).slice(0, 200)
        } else {
          groupList = this.$baseList.slice(0, 200)
        }
        const value = this.value || this.groupId
        this.groupList = value ? groupList.filter(v => v.id !== value) : groupList
      }
    },
    async loadData () {
      this.loading = true
      this.$baseList = await request.get('/goods_group')
      this.loading = false
      if (this.value) {
        this.defaultItem = this.$baseList.find(v => v.id === this.value) || null
      } else {
        this.defaultItem = null
      }
      this.groupList = this.$baseList.slice(0, 200)
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
