<style lang="less" scoped>
  .group-goods-select {
    display: flex;
    flex-direction: column;
  }
  .types {
    margin-bottom: 5px;
    border: 1px solid #EEE;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    .item {
      flex: 1;
      text-align: center;
      white-space: nowrap;
      font-weight: bold;
      cursor: pointer;
      position: relative;
      & + .item {
        &::before {
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          content: '';
          height: 100%;
          width: 1px;
          display: inline-block;
          background-color: #ccc;
        }
      }
      transition: all .3s;
      position: relative;
      &:hover, &.active {
        color: #2F5BEA;
      }
    }
  }
</style>

<template>
  <div class="group-goods-select">
    <div class="types">
      <div class="item" :class="{ active: 1 === type }" @click="type = 1">物品组</div>
      <div class="item" :class="{ active: 2 === type }" @click="type = 2">物品</div>
    </div>
    <GroupSelect style="flex: 1;height: 0;" @select="onGroupSelect" v-if="type === 1" />
    <GoodsSelect style="flex: 1;height: 0;" @select="value => $emit('select', { type: 2, value })" v-else />
    <Drawer placement="right" :mask-closable="false" closable transfer draggable :width="30" v-model="status.detail">
      <template v-slot:header>
        {{ (group.choose ? group.choose.name : '') + ' - 物品组明细' }}
        <span style="margin-left: 10px;">数量: </span>
        <InputNumber controls-outside @on-change="onGroupVolumnChange" :min="1" v-model="group.num" />
      </template>
      <DrawerAction @confirm="groupSelect" @cancel="status.detail = false">
        <Form label-position="top">
          <!-- <FormItem label="添加数量">
            <InputNumber controls-outside @on-change="onGroupVolumnChange" :min="1" v-model="group.num" />
          </FormItem> -->
          <FormItem v-for="item in group.list" :key="item.id" :label="item.goodsName + ' ' + (item.goodsGgxh || '')">
            <InputNumber controls-outside :min="1" v-model="item.num" />
          </FormItem>
        </Form>
      </DrawerAction>
    </Drawer>
  </div>
</template>

<script>
  import GroupSelect from './index.vue'
  import GoodsSelect from '../../select.vue'
  import DrawerAction from '../../../../drawer/action.vue'
  import request from '../../../../../api'

  export default {
    components: { GroupSelect, GoodsSelect, DrawerAction },
    data () {
      return {
        type: 1,
        group: {
          choose: null,
          list: [],
          num: 1
        },
        status: {
          detail: false
        }
      }
    },
    methods: {
      onGroupVolumnChange () {
        this.group.list.forEach(item => {
          item.num = this.group.num * item.volume
        })
      },
      async onGroupSelect (group) {
        this.group.choose = group
        this.group.num = 1
        this.status.detail = true
        const list = await request.get('/goods_group/detail?goodsGroupId=' + group.id)
        this.group.list = list.map(item => {
          item.num = item.volume
          return item
        })
      },
      groupSelect () {
        let detailMap = {}
        this.group.list.forEach(item => {
          detailMap[item.id] = item.num
        })
        // console.log({
        //   type: 1, value: {
        //     id: this.group.choose.id,
        //     num: this.group.num,
        //     detailMap
        //   }
        // })
        this.$emit('select', {
          type: 1, value: {
            id: this.group.choose.id,
            num: this.group.num,
            detailMap
          }
        })
        this.status.detail = false
      }
    }
  }
</script>